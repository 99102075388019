<template>
  <div id="app"><router-view></router-view></div>
</template>

<script>
export default {
    name: 'App',
    mounted () {
        this.prepareToBack();
    },
    methods: {
        prepareToBack () {
            // document.hidden是一个布尔值，简单的表示标签页显示或者隐藏
            const hiddenProperty = 'hidden' in document ? 'hidden'
                : 'webkitHidden' in document ? 'webkitHidden'
                    : 'mozHidden' in document ? 'mozHidden'
                        : null;
            const visibilityChangeEvent = hiddenProperty.replace(/hidden/i, 'visibilitychange');
            const onVisibilityChange = () => {
                if (document[hiddenProperty]) { // 页面隐藏
                    console.log('app页面隐藏');
                    window.webBuriedFn && window.webBuriedFn('pageDuration', '页面停留时长');
                } else { // 页面展示
                    console.log('app页面展示');
                    window.webBuriedFn && window.webBuriedFn('pageView', '页面浏览');
                }
            };
            // 监听标签页显示隐藏（visibilitychange事件）
            document.addEventListener(visibilityChangeEvent, onVisibilityChange, false);
        }
    }
};
</script>

<style>
</style>
