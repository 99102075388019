function sleep (second = 1) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, second * 1000);
    });
}
function getUrlParamsList () {
    var query = location.search.substring(1);
    query = query.split('&');
    var params = {};
    query && query.map(item => {
        const q = item.split('=');
        if (q.length === 2) params[q[0]] = q[1];
    });
    return params;
}

export {
    sleep,
    getUrlParamsList
};
