import { getAddressByIp } from '@/service/common';

export default function initWebBuried () {
    // 普通H5获取城市
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('miniprogram') === -1 && !window.AndroidWebView && !window.LMGetID1) {
        window.leapWebBuiredJS.initWebBuriedData({isWeb: true});
        getAddressByIp().then(res => {
            window.leapWebBuiredJS.initWebBuriedData({city: res.data.city, isH5CityReady: true});
        }).catch(() => {
            window.leapWebBuiredJS.initWebBuriedData({isH5CityReady: true});
        });
        console.log('H5-------------');
    }

    window.leapWebBuiredJS.initWebBuriedData({
        localEnv: window.location.origin.indexOf('apptec-invite.leapmotor.com') > -1 ? '1' : '0',
        pageConfig: {
            22000: {
                pageName: '邀请有礼首页',
            },
            27000: {
                pageName: '转介绍海报页',
            },
            23000: {
                pageName: '接受邀请页',
            }
        }
    });
    window.webBuriedFn = function (type, actionValue = '', labelData = {}) {
        window.leapWebBuiredJS.baseWebBuriedFn({ type, actionValue, labelData });
    };
}
