import Vue from 'vue';
import App from './App.vue';
import router from './router';
import './vant';
import { isProd } from '@/utils/env';
import { CoreLMGetID1 } from './utils/app-core';
import initWebBuried from '@/utils/webBuired';

import Vconsole from 'vconsole';
if (!isProd) {
    const vConsole = new Vconsole();
    Vue.use(vConsole);
}

// 获取用户信息
CoreLMGetID1().then(res => {
    console.log('mainjs-LMGetID', res);
    window._userInfo = res;
});

// 登录成功后重新缓存用户信息
window.LMRtnStr = (data) => {
    console.log('进入了main方法回调!', data);
    window._userInfo = JSON.parse(data);
};

// 初始化埋点
initWebBuried();

Vue.config.productionTip = false;


new Vue({
    render: h => h(App),
    router
}).$mount('#app');
