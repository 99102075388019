import { ajax } from './axios';
import JsEncrypt from 'jsencrypt';
import qs from 'qs';
const axios = ajax();
function encryptAxios (params) {
    if (params.method === 'GET') {
        console.log(params.params, '==========get参数');
        if (params.params && params.params.phoneCiphertext) {
            params.params.phoneCiphertext = encryption(params.params.phoneCiphertext);
        }
    } else {
        console.log(params.data, '==========post参数');
        if (params.data && params.data.phoneCiphertext) {
            params.data.phoneCiphertext = encryption(params.data.phoneCiphertext);
        }
        if (params.isQs) params.data = qs.stringify(params.data);
    }
    return axios(params);
}
function encryption (data) {
    const jse = new JsEncrypt();
    var publicString = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDHUIQKhkwNqJFTZPe98mC1lmpbY9r/+7PEWZg8ebqYXT3sumKRaQ0zcoTx42x0iybmCRXy4CcZrgGAbwKzwqwNw0rFquJ6c7mgQA6k3lZU3p96qBlzK7DSkoFR6mO9pjcd2hlJ8wH+IwI5b8IWWZhwVN/4cM7npG0S0zeRn3soEwIDAQAB';
    jse.setPublicKey(publicString);
    const encryptedData = jse.encrypt(data);
    return encryptedData;
}

export default encryptAxios;