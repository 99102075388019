import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes =[
    {
        path:'/',
        redirect:'/inviteHomePage.html'
    },
    // 邀请有礼
    {
        path: '/inviteHomePage.html',
        name: 'inviteHomePage',
        component: () => import('@/pages/thematic-framework/InviteHomePage/index.vue')
    },
    {
        // 转介绍邀请明细页面
        path: '/invitationDetails.html',
        name: 'invitationDetails',
        component: () => import('@/pages/thematic-framework/InvitationDetails/index.vue')
    },
    {
        // 被邀请用户成功页面
        path: '/invitedSuccess.html',
        name: 'invitedSuccess',
        component: () => import('@/pages/thematic-framework/InvitedSuccess/index.vue'),
        meta: {
            title: '零跑'
        }
    },
    {
        // 邀请海报页面
        path: '/inviteReport.html',
        name: 'inviteReport',
        component: () => import('@/pages/thematic-framework/InviteReport/index.vue')
    },
    {
        // 123
        path: '/InviteQrcode.html',
        name: 'InviteQrcode',
        component: () => import('@/pages/thematic-framework/InviteQrcode/index.vue')
    },
    {
        path: '*',
        name: 'NotFound',
        component: () => import('@/components/404.vue'),
        meta: {
            title: '零跑汽车'
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    routes
});
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : '';
    if (from.path !== '/' && from.path !== to.path) { // 页面切换,H5之间路由跳转
        console.log('router-beforeEach--page');
        window.webBuriedFn && window.webBuriedFn('pageDuration', '页面停留时长'); // 用户埋点统计停留在页面的时长
    }
    next();
});
export default router;
