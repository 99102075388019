import { isProd, isDev } from './env';
const useMock = isDev;
const bridge = {
    CoreLPCloseDownRefresh: () => window.AndroidWebView ? window.AndroidWebView.LPCloseDownRefresh() : LPCloseDownRefresh(),
    CoreLMGetID1: () => new Promise((resolve, reject) => {
        let r;
        const client = isProd ? 'd9bc3ebda50f4132a6937dfdf8707a02' : '39b342e06405439c8d921b0b15a31980'; // 39测试 d9正式
        if (window.AndroidWebView) {
            r = window.AndroidWebView.LMGetID1(client);
            r = typeof r === 'string' ? JSON.parse(r) : r;
            resolve(r);
        } else {
            if (!!window.LMGetID1 && typeof window.LMGetID1 === 'function') {
                r = window.LMGetID1(client) || {};
                r = typeof r === 'string' ? JSON.parse(r) : r;
                resolve(r);
                return;
            }
            let count = 0;
            const timer = setInterval(function () {
                count++;
                if (count > 12) {
                    clearInterval(timer);
                    reject(count);
                }
                if (!!window.LMGetID1 && typeof window.LMGetID1 === 'function') {
                    r = window.LMGetID1(client) || {};
                    clearInterval(timer);
                    r = typeof r === 'string' ? JSON.parse(r) : r;
                    resolve(r);
                }
            }, 500);
        }
    }),
    LMSyncGetID1: () => {
        let r;
        const client = isProd ? 'd9bc3ebda50f4132a6937dfdf8707a02' : '39b342e06405439c8d921b0b15a31980';
        if (window.AndroidWebView) {
            r = window.AndroidWebView.LMGetID1(client);
        } else {
            r = window.LMGetID1(client) || {};
        }
        r = typeof r === 'string' ? JSON.parse(r) : r;
        return r;
    },
    // 重新刷token
    LPMRefreshToken: () => {
        let res;
        const client = isProd ? 'd9bc3ebda50f4132a6937dfdf8707a02' : '39b342e06405439c8d921b0b15a31980';
        if (window.AndroidWebView) {
            res = window.AndroidWebView.LPMRefreshToken(client);
            res = typeof res === 'string' ? JSON.parse(res) : res;
            return res;
        } else {
            if (!!window.LPMRefreshToken && typeof window.LPMRefreshToken === 'function') {
                res = window.LPMRefreshToken(client) || {};
                res = typeof res === 'string' ? JSON.parse(res) : res;
                return res;
            }
        }
    },
    CoreLMPushToURL: (...args) => {
        window.AndroidWebView ? window.AndroidWebView.LMPushToURL(...args) : LMPushToURL(...args);
    },
    CoreLMPopWebView: () => {
        window.AndroidWebView ? window.AndroidWebView.LMPopWebView() : LMPopWebView();
    },
    CoreLMShareURLtoSocialNet: (...args) => window.AndroidWebView ? window.AndroidWebView.LMShareURLtoSocialNet(...args) : LMShareURLtoSocialNet(...args),
    CoreLMGetID2: (...args) => window.AndroidWebView ? window.AndroidWebView.LMGetID2(...args) : LMGetID2(...args),
    // 保存
    CoreLMSaveBase64Bitmap: obj => window.AndroidWebView ? window.AndroidWebView.LMSaveBase64Bitmap(JSON.stringify(obj)) : LMSaveBase64Bitmap(JSON.stringify(obj)),
    // 指定路径分享
    CoreLMShareURLtoChannel: obj => window.AndroidWebView ? window.AndroidWebView.LMShareURLtoChannel(JSON.stringify(obj)) : LMShareURLtoChannel(JSON.stringify(obj)),
    CoreCheck: (api) => {
        return new Promise((resolve) => {
            if (window.AndroidWebView) {
                resolve(!!window.AndroidWebView[String(api)]);
            } else {
                let count = 0;
                const timer = setInterval(function () {
                    count++;
                    if (count > 12) {
                        clearInterval(timer);
                        resolve(false);
                    }
                    if (!!window[String(api)] && typeof window[String(api)] === 'function') {
                        resolve(true);
                    }
                }, 500);
            }
        }).catch((e) => {
            console.log(e);
        });
    }
};

const analogBridge = {
    CoreLPCloseDownRefresh: () => console.log('禁止下拉刷新调用成功'),
    CoreLMGetID1: () => Promise.resolve({
        LMPhoneNum: '13812345678', // '18100185420',
        LMAccID: '409086581652066304', // '1807601000169577',
        delegationToken: 'd880194c-2478-4abe-b720-e225c1347153',
        APPToken: 'F6E597190BA94B2F92A749348682B00AF6E597190BA94B2F92A749348682B00A'
    }),
    LMSyncGetID1: () => {
        return {
            LMPhoneNum: '13812345678',
            LMAccID: '409086581652066304', // '1807601000169577',
            delegationToken: 'd880194c-2478-4abe-b720-e225c1347153',
            APPToken: 'F6E597190BA94B2F92A749348682B00AF6E597190BA94B2F92A749348682B00A'
        };
    },
    LPMRefreshToken: () => {
        return {
            LMPhoneNum: '18100185420', // '18100185420',
            LMAccID: '153735064750919680', // '1807601000169577',
            delegationToken: 'c2efd756-2356-4ccd-b45e-a4643920970d',
            APPToken: 'bpmfdtnlgkh'
        };
    },
    CoreLMPushToURL (path) {
        /^([a-z][a-z\d+\-\\.]*:)?\/\//i.test(path) ? location.href = path
            : this.$router.push({
                path
            });
    },
    CoreLMPopWebView () {
        this.$router.back();
    },
    CoreLMShareURLtoSocialNet: () => {
        console.log('分享调用成功');
    },
    CoreLMGetID2: () => console.log('唤起APP登录弹窗'),
    CoreLMSaveBase64Bitmap: () => console.log('保存图片'),
    CoreLMShareURLtoChannel: obj => console.log('指定路径分享', obj),
    CoreCheck: () => Promise.resolve(true)
};

const mobileCode = useMock ? analogBridge : bridge;
const {
    CoreLPCloseDownRefresh,
    CoreLMGetID1,
    LMSyncGetID1,
    LPMRefreshToken,
    CoreLMGetID2,
    CoreLMPushToURL,
    CoreLMPopWebView,
    CoreLMShareURLtoSocialNet,
    CoreLMSaveBase64Bitmap,
    CoreLMShareURLtoChannel,
    CoreCheck
} = mobileCode;
export {
    CoreLPCloseDownRefresh,
    CoreLMGetID1,
    LMSyncGetID1,
    LPMRefreshToken,
    CoreLMGetID2,
    CoreLMPushToURL,
    CoreLMPopWebView,
    CoreLMShareURLtoSocialNet,
    CoreLMSaveBase64Bitmap,
    CoreLMShareURLtoChannel,
    CoreCheck
};
