
// vant组件按需引入
import Vue from 'vue';
import {
    Cell,
    Icon,
    Field,
    Cascader,
    Picker,
    Popup,
    Checkbox,
    List,
    Tabs,
    Tab,
    Button,
    Toast,
    Overlay,
    Divider
} from 'vant';
Vue.use(Cell);
Vue.use(Icon);
Vue.use(Field);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(Checkbox);
Vue.use(Tabs);
Vue.use(Tab);
Vue.use(List);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Overlay);
Vue.use(Divider);

