const { protocol, host } = location;
const leapProtocol = protocol === 'https:' ? 'https:' : 'http:';
const INVITE_HOST_PROD = 'apptec-invite.leapmotor';
const INVITE_HOST_TEST = 'apptec-invite-test.leapmotor';

const isProd = host.indexOf(INVITE_HOST_PROD) > -1;
const isTest = host.indexOf(INVITE_HOST_TEST) > -1;
const isDev = process.env.NODE_ENV === 'development';

const leapInviteOrigin = `${leapProtocol}//${host}`; // 本项目域名
const axiosBaseUrl = isDev ? `${leapProtocol}//${host}/proxy` : `${leapProtocol}//${isProd ? 'app-front-gateway.leapmotor.cn' : 'app-front-gateway-test.leapmotor.cn'}`; // 社区服务接口地址
const lpactOrigin = isProd ? 'https://lpact.leapmotor.com' : 'https://lpact-test.leapmotor.com'; // 0元购项目
const leapCommunityOrigin = isProd ? 'https://apptec.leapmotor.cn' : 'https://apptec-test.leapmotor.cn'; // 社区项目域名
const scrmService = isProd ? 'https://scrmapi.leapmotor.com' : 'https://scrmapi-uat.leapmotor.com'; // scrm api
const userService = isProd ? 'https://appuser.leapmotor.cn' : 'https://appuser-test.leapmotor.cn'; // user api


export {
    isProd,
    isDev,
    isTest,
    lpactOrigin,
    leapInviteOrigin,
    axiosBaseUrl,
    leapCommunityOrigin,
    scrmService,
    userService
};
